var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();}}},[_c('div',{staticClass:"d-flex flex-row flex-wrap"},[_c('div',{staticClass:"col-lg-4 col-12 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"prefix"}},[_vm._v("คำนำหน้า")]),_c('v-select',{attrs:{"id":"prefix","options":_vm.prefixs,"disabled":_vm.disabled,"loading":_vm.isFetchingPrefix},model:{value:(_vm.prefix),callback:function ($$v) {_vm.prefix=$$v},expression:"prefix"}}),(
          _vm.invalid &&
          _vm.validation.formStudent.mPrefixId &&
          !_vm.validation.formStudent.mPrefixId.required
        )?_c('small',{staticClass:"text-danger"},[_vm._v(" กรุณาเลือกคำนำหน้า ")]):_vm._e()],1),_c('div',{staticClass:"col-lg-4 col-12 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"firstName"}},[_vm._v("ชื่อ")]),_c('b-form-input',{attrs:{"id":"firstName","state":_vm.invalid ? _vm.validation.formStudent.first_name.required : null,"type":"text","disabled":_vm.disabled},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"firstName-feedback"}},[_vm._v(" กรุณากรอกชื่อ ")])],1),_c('div',{staticClass:"col-lg-4 col-12 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"lastName"}},[_vm._v("นามสกุล")]),_c('b-form-input',{attrs:{"id":"lastName","state":_vm.invalid ? _vm.validation.formStudent.last_name.required : null,"type":"text","disabled":_vm.disabled},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('b-form-invalid-feedback',{attrs:{"id":"lastName-feedback"}},[_vm._v(" กรุณากรอกนามสกุล ")])],1),_c('div',{staticClass:"col-lg-4 col-12 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"cardtype"}},[_vm._v("ประเภทบัตร")]),_c('v-select',{attrs:{"id":"cardtype","options":_vm.cardtypes,"disabled":_vm.disabled,"loading":_vm.isFetchingCardtype},model:{value:(_vm.cardtype),callback:function ($$v) {_vm.cardtype=$$v},expression:"cardtype"}}),(
          _vm.invalid &&
          _vm.validation.formStudent.mCardtypeId &&
          !_vm.validation.formStudent.mCardtypeId.required
        )?_c('small',{staticClass:"text-danger"},[_vm._v(" กรุณาเลือกประเภทบัตร ")]):_vm._e()],1),_c('div',{staticClass:"col-lg-4 col-12 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"idCard"}},[_vm._v("เลขบัตรประชาชน")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('#-####-#####-##-#'),expression:"'#-####-#####-##-#'"}],attrs:{"id":"idCard","state":_vm.invalid
            ? _vm.validation.formStudent.id_card.required &&
              _vm.validation.formStudent.id_card.valid
            : null,"type":"text","placeholder":"x-xxxx-xxxxx-xx-x","disabled":_vm.disabled,"required":""},model:{value:(_vm.form.id_card),callback:function ($$v) {_vm.$set(_vm.form, "id_card", $$v)},expression:"form.id_card"}}),_c('b-form-invalid-feedback',{attrs:{"id":"idCard-feedback"}},[(!_vm.validation.formStudent.id_card.required)?_c('span',[_vm._v(" กรุณากรอกเลขบัตรประชาชน ")]):(!_vm.validation.formStudent.id_card.valid)?_c('span',[_vm._v(" เลขบัตรประชาชนไม่ถูกต้อง ")]):_vm._e()])],1),_c('div',{staticClass:"col-lg-4 col-12 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"phoneNumber"}},[_vm._v("เบอร์โทรศัพท์")]),_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('0##-###-####'),expression:"'0##-###-####'"}],attrs:{"id":"phoneNumber","state":_vm.invalid
            ? _vm.validation.formStudent.phone_number.required &&
              _vm.validation.formStudent.phone_number.minLength &&
              _vm.validation.formStudent.phone_number.maxLength
            : null,"type":"tel","placeholder":"0xx-xxx-xxxx","disabled":_vm.disabled},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('b-form-invalid-feedback',{attrs:{"id":"phoneNumber-feedback"}},[(!_vm.validation.formStudent.phone_number.required)?_c('span',[_vm._v(" กรุณากรอกเบอร์โทรศัพท์ ")]):(
            !_vm.validation.formStudent.phone_number.minLength ||
            !_vm.validation.formStudent.phone_number.maxLength
          )?_c('span',[_vm._v(" เบอร์โทรศัพท์ไม่ถูกต้อง ")]):_vm._e()])],1),_c('div',{staticClass:"col-lg-4 col-12 px-2 mb-2"},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"email"}},[_vm._v("อีเมล")]),_c('b-form-input',{attrs:{"id":"email","state":_vm.invalid
            ? _vm.validation.formStudent.email.required &&
              _vm.validation.formStudent.email.email
            : null,"type":"email","disabled":_vm.disabled},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('b-form-invalid-feedback',{attrs:{"id":"phoneNumber-feedback"}},[(!_vm.validation.formStudent.email.required)?_c('span',[_vm._v(" กรุณากรอกอีเมล ")]):(!_vm.validation.formStudent.email.email)?_c('span',[_vm._v(" อีเมลไม่ถูกต้อง ")]):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }