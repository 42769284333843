<template>
  <page-content>
    <page-title heading="การจัดการนักเรียน">
      <template v-slot:right>
        <!-- <b-button variant="danger" @click="onCreate"
          >เพิ่มรายชื่อนักเรียน</b-button
        > -->
      </template>
    </page-title>

    <b-row>
      <b-col cols="12">
        <filter-master
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          :col-headquarter="3"
          :col-division="3"
          :col-station="3"
          :col-school="3"
          :hiddenInput="{ schoolClass: true }"
        >
        </filter-master>
      </b-col>

      <b-col cols="10" md="6" class="mt-2 pr-0">
        <b-form-group class="px-2">
          <b-input-group class="input-group-seamless">
            <b-form-input
              placeholder="ค้นหารายชื่อนักเรียน"
              v-model="keyword"
              @keyup.enter="onKeywordSearched"
            ></b-form-input>

            <b-input-group-text slot="prepend">
              <font-awesome-icon class="mx-auto" icon="search" />
            </b-input-group-text>

            <b-input-group-text v-show="keyword.length" slot="append">
              <a href="javascript:void(0)" @click="clearKeyword">
                <font-awesome-icon class="mx-auto" icon="window-close" />
              </a>
            </b-input-group-text>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="2" class="mt-2 pl-0">
        <b-button variant="primary" block size="sm" @click="onKeywordSearched"
          >ค้นหา</b-button
        >
      </b-col>
    </b-row>

    <student-table
      ref="table"
      class="mt-4"
      :fields="fields"
      :params="formfilter"
      @edit="onEdit"
      @delete="onDelete"
    />

    <create-or-update-student
      :edit-data.sync="editData"
      v-model="showModalStudent"
      @create:success="onCreateSuccess"
    ></create-or-update-student>

    <delete-student
      v-model="showModalDelete"
      @delete:success="onDeleteSuccess"
      :delete-data.sync="deleteData"
    ></delete-student>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
import PageTitle from "../../components/layout/PageTitle";
import CreateOrUpdateStudent from "../../components/modal/CreateOrUpdateStudent";
import StudentTable from "../../components/table/Student";
import FilterMaster from "../../components/form/FilterMaster";
import DeleteStudent from "../../components/modal/DeleteStudent";
import { Auth } from "../../models";

export default {
  components: {
    PageContent,
    PageTitle,
    CreateOrUpdateStudent,
    StudentTable,
    DeleteStudent,
    FilterMaster,
  },

  data() {
    return {
      keyword: "",
      formfilter: {},
      editData: {},
      deleteData: {},
      showModalStudent: false,
      showModalDelete: false,
      fields: [
        // { key: "order", label: "ลำดับ" },
        { key: "prefix", label: "คำนำหน้า" },
        { key: "first_name", label: "ชื่อ" },
        { key: "last_name", label: "สกุล" },
        { key: "id_card", label: "เลขบัตรประชาชน" },
        {
          key: "schoolName",
          label: "โรงเรียน",
        },
        { key: "classRoom", label: "ห้อง" },
        { key: "yearTerm", label: "ปีการศึกษา" },
        {
          key: "created_at",
          label: "วันที่สร้าง",
        },
      ],
    };
  },

  // watch: {
  //   formfilter: {
  //     deep: true,
  //     handler: "onFormFilterChanged",
  //   },
  // },

  computed: {
    authUser() {
      return Auth.user();
    },
  },

  methods: {
    onKeywordSearched() {
      this.$set(this.formfilter, "keyword", this.keyword);
    },

    clearKeyword() {
      this.keyword = "";
      this.$delete(this.formfilter, "keyword");
    },

    // onFormFilterChanged(formFilter) {
    //   const query = {};
    //   const { authUser } = this;

    //   if (!authUser.mHeadquarterId && formFilter.mHeadquarterId) {
    //     this.$set(query, "mHeadquarterId", formFilter.mHeadquarterId);
    //   }

    //   if (!authUser.mDivisionId && formFilter.mDivisionId) {
    //     this.$set(query, "mDivisionId", formFilter.mDivisionId);
    //   }

    //   if (!authUser.mStationId && formFilter.mStationId) {
    //     this.$set(query, "mStationId", formFilter.mStationId);
    //   }

    //   console.log(query, formFilter);
    //   if (Object.keys(query).length) {
    //     this.$router.replace({
    //       name: "MasterDataTeacher",
    //       query,
    //     });
    //   }
    // },

    // onCreate() {
    //   this.showModalStudent = true;
    // },

    async onEdit(item) {
      this.editData = { ...item };

      //   const promise = await User.api().findAll({
      //     teachingDareId: item.id,
      //   });

      //   const { data = [] } = promise.response.data;

      //   if (data.length) {
      //     this.$set(
      //       this.editData,
      //       "teaching_plans",
      //       sortBy(data, "createdAt").map(
      //         ({ lesson_number, lesson_name, date, time, content }) => ({
      //           lesson_number,
      //           lesson_name,
      //           date,
      //           time,
      //           content,
      //         })
      //       )
      //     );
      //   }

      this.showModalStudent = true;
    },

    onCreateSuccess() {
      this.$refs.table.refresh();
    },

    onDelete(item) {
      this.deleteData = { ...item };
      this.showModalDelete = true;
    },

    onDeleteSuccess() {
      this.$refs.table.refresh();
    },
  },
};
</script>
