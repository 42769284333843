<template>
  <b-modal
    id="create-or-update-student-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">เพิ่ม/แก้ไขข้อมูลนักเรียน</h3>

      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด:
        {{ $_formatDateMixin_formatShort(editData.updatedAt) }}</span
      >
    </template>

    <student-form
      v-model="formStudent"
      :edit-data="editData"
      :disabled="isSubmiting"
      :invalid.sync="invalid"
      :validation="$v"
    ></student-form>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>
      <b-button size="sm" variant="primary" @click.prevent="save">
        <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner>
        <span v-else>บันทึก</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { validationMixin } from "vuelidate";
import {
  // email,
  required,
  // minLength,
  // maxLength,
} from "vuelidate/lib/validators";
import formatDateMixin from "../../mixins/formatDateMixin";
import validatorMixin from "../../mixins/validatorMixin";
import StudentForm from "../form/Student";
import { Auth, StudentDare } from "../../models";

export default {
  mixins: [formatDateMixin, validatorMixin, validationMixin],

  components: {
    StudentForm,
  },

  props: {
    show: Boolean,
    editData: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      isSubmiting: false,
      invalid: false,
      formStudent: {},
    };
  },

  validations: {
    formStudent: {
      // mHeadquarterId: { required },
      // mDivisionId: { required },
      // mStationId: { required },
      mPrefixId: { required },
      mCardtypeId: { required },
      first_name: { required },
      last_name: { required },
      // birth_date: { required },
      phone_number: {
        // required,
        // minLength: minLength(12),
        // maxLength: maxLength(12),
      },
      id_card: {
        required,
        valid: validatorMixin.methods.$_validatorMixin_chkDigitPid,
      },
      email: {
        // required,
        // email,
      },
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    async save() {
      this.$v.$touch();

      this.invalid = false;

      if (this.$v.$invalid) {
        this.invalid = true;

        return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      }

      const formData = {
        ...this.formStudent,
        id_card: this.formStudent.id_card.replace(/-/g, ""),
        phone_number: this.formStudent.phone_number.replace(/-/g, ""),
      };

      let promise;

      this.isSubmiting = true;

      try {
        if (this.editData && this.editData.id) {
          promise = await StudentDare.api().update(this.editData.id, formData);
        } else {
          promise = await StudentDare.api().create(formData);
        }

        const { data } = promise.response;

        if (data && data.response_status == "SUCCESS") {
          this.$toast.success(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลนักเรียนเรียบร้อยแล้ว`
          );

          this.$bvModal.hide("create-or-update-student-modal");

          this.$emit("create:success");
        } else {
          this.$toast.error(
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลนักเรียนไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
          );
        }
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.isSubmiting = false;
      }
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.invalid = false;

      this.formStudent = {};

      this.$emit("update:editData", {});
    },
  },
};
</script>
