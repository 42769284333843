<template>
  <b-form @submit.stop.prevent>
    <div class="d-flex flex-row flex-wrap">
      <!-- <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="headQuarter">ภาค</label>
        <v-select
          id="headQuarter"
          v-model="headQuarter"
          :options="headQuarters"
          :disabled="disabled || disabledInput.headQuarter"
          :loading="isFetchingHeadQuarter"
        ></v-select>

        <small
          v-if="
            invalid &&
              validation.formStudent.mHeadquarterId &&
              !validation.formStudent.mHeadquarterId.required
          "
          class="text-danger"
        >
          กรุณาเลือกภาค
        </small>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="division">จังหวัด</label>
        <v-select
          id="division"
          v-model="division"
          :options="divisions"
          :disabled="disabled || disabledInput.division"
          :loading="isFetchingDivision"
        ></v-select>

        <small
          v-if="
            invalid &&
              validation.formStudent.mDivisionId &&
              !validation.formStudent.mDivisionId.required
          "
          class="text-danger"
        >
          กรุณาเลือกจังหวัด
        </small>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="station">สถานี</label>
        <v-select
          id="station"
          v-model="station"
          :options="stations"
          :disabled="disabled || disabledInput.station"
          :loading="isFetchingStation"
        ></v-select>

        <small
          v-if="
            invalid &&
              validation.formStudent.mStationId &&
              !validation.formStudent.mStationId.required
          "
          class="text-danger"
        >
          กรุณาเลือกสถานี
        </small>
      </div> -->

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="prefix">คำนำหน้า</label>
        <v-select
          id="prefix"
          v-model="prefix"
          :options="prefixs"
          :disabled="disabled"
          :loading="isFetchingPrefix"
        ></v-select>

        <small
          v-if="
            invalid &&
            validation.formStudent.mPrefixId &&
            !validation.formStudent.mPrefixId.required
          "
          class="text-danger"
        >
          กรุณาเลือกคำนำหน้า
        </small>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="firstName">ชื่อ</label>

        <b-form-input
          id="firstName"
          :state="invalid ? validation.formStudent.first_name.required : null"
          type="text"
          :disabled="disabled"
          v-model="form.first_name"
        ></b-form-input>

        <b-form-invalid-feedback id="firstName-feedback">
          กรุณากรอกชื่อ
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="lastName">นามสกุล</label>

        <b-form-input
          id="lastName"
          :state="invalid ? validation.formStudent.last_name.required : null"
          type="text"
          :disabled="disabled"
          v-model="form.last_name"
        ></b-form-input>

        <b-form-invalid-feedback id="lastName-feedback">
          กรุณากรอกนามสกุล
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="cardtype">ประเภทบัตร</label>
        <v-select
          id="cardtype"
          v-model="cardtype"
          :options="cardtypes"
          :disabled="disabled"
          :loading="isFetchingCardtype"
        ></v-select>

        <small
          v-if="
            invalid &&
            validation.formStudent.mCardtypeId &&
            !validation.formStudent.mCardtypeId.required
          "
          class="text-danger"
        >
          กรุณาเลือกประเภทบัตร
        </small>
      </div>

      <!-- <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="userStatus"
          >สิทธิ์การสอน</label
        >
        <input
          id="userStatus"
          class="form-control"
          type="text"
          :disabled="disabled"
          v-model.number="form.user_status"
        />
      </div> -->

      <!-- <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="birthDate">วัน/เดือน/ปีเกิด</label>
        <datepicker
          ref="datepicker"
          id="birthDate"
          input-class="form-control"
          required
          :language="th"
          :format="formatBuddhistBirthDate"
          v-model="birthDate"
        ></datepicker>

        <small
          v-if="invalid && !validation.formStudent.birth_date.required"
          class="text-danger"
        >
          กรุณาเลือกวัน/เดือน/ปีเกิด
        </small>
      </div> -->

      <!-- <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="retireYear">ปีเกษียณ</label>

        <b-form-input
          id="retireYear"
          :state="null"
          type="text"
          :disabled="disabled"
          placeholder="2xxx"
          v-mask="'####'"
          v-model="form.retire_year"
        ></b-form-input>
      </div> -->

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="idCard">เลขบัตรประชาชน</label>

        <b-form-input
          id="idCard"
          :state="
            invalid
              ? validation.formStudent.id_card.required &&
                validation.formStudent.id_card.valid
              : null
          "
          type="text"
          placeholder="x-xxxx-xxxxx-xx-x"
          :disabled="disabled"
          required
          v-mask="'#-####-#####-##-#'"
          v-model="form.id_card"
        ></b-form-input>

        <b-form-invalid-feedback id="idCard-feedback">
          <span v-if="!validation.formStudent.id_card.required">
            กรุณากรอกเลขบัตรประชาชน
          </span>

          <span v-else-if="!validation.formStudent.id_card.valid">
            เลขบัตรประชาชนไม่ถูกต้อง
          </span>
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="phoneNumber">เบอร์โทรศัพท์</label>

        <b-form-input
          id="phoneNumber"
          :state="
            invalid
              ? validation.formStudent.phone_number.required &&
                validation.formStudent.phone_number.minLength &&
                validation.formStudent.phone_number.maxLength
              : null
          "
          type="tel"
          placeholder="0xx-xxx-xxxx"
          :disabled="disabled"
          v-mask="'0##-###-####'"
          v-model="form.phone_number"
        ></b-form-input>

        <b-form-invalid-feedback id="phoneNumber-feedback">
          <span v-if="!validation.formStudent.phone_number.required">
            กรุณากรอกเบอร์โทรศัพท์
          </span>

          <span
            v-else-if="
              !validation.formStudent.phone_number.minLength ||
              !validation.formStudent.phone_number.maxLength
            "
          >
            เบอร์โทรศัพท์ไม่ถูกต้อง
          </span>
        </b-form-invalid-feedback>
      </div>

      <div class="col-lg-4 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="email">อีเมล</label>

        <b-form-input
          id="email"
          :state="
            invalid
              ? validation.formStudent.email.required &&
                validation.formStudent.email.email
              : null
          "
          type="email"
          :disabled="disabled"
          v-model="form.email"
        ></b-form-input>

        <b-form-invalid-feedback id="phoneNumber-feedback">
          <span v-if="!validation.formStudent.email.required">
            กรุณากรอกอีเมล
          </span>

          <span v-else-if="!validation.formStudent.email.email">
            อีเมลไม่ถูกต้อง
          </span>
        </b-form-invalid-feedback>
      </div>

      <!-- <div class="col-12 px-2 my-2">
        <label class="font-weight-bold" for="address"
          >ที่อยู่ปัจจุบันที่สามารถติดต่อได้</label
        >

        <b-form-textarea
          id="address"
          :state="null"
          :disabled="disabled"
          v-model="form.address"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>

      <div class="col-12 px-2 my-2">
        <label class="font-weight-bold" for="addressDocument"
          >ที่อยู่สำหรับจัดส่งเอกสาร</label
        >

        <b-form-textarea
          id="addressDocument"
          :state="null"
          :disabled="disabled"
          v-model="form.address_document"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div> -->

      <!-- <div v-if="isClass" class="col-lg-2 col-12 px-2 mb-2">
        <label class="font-weight-bold" for="box5"
          >จำนวนนักเรียนจริง</label
        >
        <input
          class="form-control custom__height"
          type="number"
          :min="0"
          :disabled="disabled"
          v-model.number="form.actual_student"
        />
      </div> -->
    </div>
  </b-form>
</template>

<script>
// import {
//   toBuddhistCalendar,
//   formatBuddhistBirthDate,
// } from "../../helpers/datepicker-helper";
// import formatDateMixin from "../../mixins/formatDateMixin";
import vSelect from "vue-select";
// import { th } from "vuejs-datepicker/dist/locale";
// import Datepicker from "vuejs-datepicker";
import { MPrefix, MCardType } from "../../models";

// toBuddhistCalendar(Datepicker);

// const currentYear = new Date().getFullYear() + 543;

export default {
  // mixins: [formatDateMixin],

  props: {
    formData: Object,
    editData: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    validation: {
      type: Object,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    disabledInput: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "formData",
    event: "change",
  },

  components: {
    // Datepicker,

    "v-select": vSelect,
  },

  data() {
    return {
      // th,
      // isFetchingHeadQuarter: false,
      // isFetchingDivision: false,
      // isFetchingStation: false,
      isFetchingPrefix: false,
      isFetchingCardtype: false,
      // headQuarter: null,
      // division: null,
      // station: null,
      prefix: null,
      cardtype: null,
      // birthDate: this.editData.bd_date
      //   ? new Date(this.editData.bd_date).setFullYear(
      //       new Date(this.editData.bd_date).getFullYear() - 543
      //     )
      //   : null,
      form: {
        // address: this.editData.address || "",
        // address_document: this.editData.address_document || "",
        first_name: this.editData.first_name || "",
        last_name: this.editData.last_name || "",
        // bd_date: this.editData.bd_date || "",
        // birth_date: this.editData.birth_date || "",
        // retire_year: this.editData.retire_year || "",
        id_card: this.editData.id_card || "",
        phone_number: this.editData.phone_number || "",
        email: this.editData.email || null,
        // mHeadquarterId: this.editData.mHeadquarterId || null,
        // mDivisionId: this.editData.mDivisionId || null,
        // mStationId: this.editData.mStationId || null,
        mPrefixId: this.editData.mPrefixId || null,
        mCardtypeId: this.editData.mCardtypeId || null,
      },
    };
  },

  watch: {
    form: {
      deep: true,
      immediate: true,
      handler: "syncData",
    },

    // headQuarter: {
    //   handler: "onHeadQuarterChanged",
    // },

    // division: {
    //   handler: "onDivisionChanged",
    // },

    // station: {
    //   handler: "onStationChanged",
    // },

    prefix: {
      handler: "onPrefixChanged",
    },

    cardtype: {
      handler: "onCardtypeChanged",
    },

    // birthDate: {
    //   handler: "onBirthDateChanged",
    // },
  },

  computed: {
    // headQuarters() {
    //   return MHeadquarter.all().map((record) => ({
    //     id: record.id,
    //     label: record.m_headquarters_name,
    //   }));
    // },

    // divisions() {
    //   return this.headQuarter
    //     ? MDivision.query()
    //         .where("mHeadquarterId", this.headQuarter.id)
    //         .get()
    //         .map((record) => ({
    //           id: record.id,
    //           label: record.m_division_name,
    //         }))
    //     : [];
    // },

    // stations() {
    //   return this.division
    //     ? MStation.query()
    //         .where("mDivisionId", this.division.id)
    //         .get()
    //         .map((record) => ({
    //           id: record.id,
    //           label: record.m_station_name,
    //         }))
    //     : [];
    // },

    prefixs() {
      return MPrefix.all().map((record) => ({
        id: record.id,
        label: record.m_prefix_name,
      }));
    },

    cardtypes() {
      return MCardType.all().map((record) => ({
        id: record.id,
        label: record.m_cardtype_name,
      }));
    },
  },

  methods: {
    // formatBuddhistBirthDate,

    async init() {
      this.isFetchingPrefix = true;
      this.isFetchingCardtype = true;

      try {
        // const authUser = await Auth.user();

        await Promise.all([
          // MHeadquarter.api().findAll({
          //   id: authUser.mHeadquarterId,
          // }),
          MPrefix.api().findAll(),
          MCardType.api().findAll(),
        ]);

        if (this.editData) {
          const { mPrefixId, mCardtypeId } = this.editData;

          // await MHeadquarter.api().findAll({
          //   id: mHeadquarterId,
          // });

          // const mHeadquarter = MHeadquarter.find(mHeadquarterId);

          // if (mHeadquarter) {
          //   this.headQuarter = {
          //     id: mHeadquarter.id,
          //     label: mHeadquarter.m_headquarters_name,
          //   };
          // }

          const mPrefix = MPrefix.find(mPrefixId);

          if (mPrefix) {
            this.prefix = {
              id: mPrefix.id,
              label: mPrefix.m_prefix_name,
            };
          }

          const mCardtype = MCardType.find(mCardtypeId);

          if (mCardtype) {
            this.cardtype = {
              id: mCardtype.id,
              label: mCardtype.m_cardtype_name,
            };
          }
        }
      } catch (error) {
        console.error(error);
        this.$toast.error("ไม่สามารถดึงข้อมูลนักเรียนได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingPrefix = false;
        this.isFetchingCardtype = false;
      }
    },

    // async onHeadQuarterChanged(headQuarter) {
    //   let id = null;

    //   this.division = null;

    //   if (headQuarter && headQuarter.id) {
    //     id = headQuarter.id;

    //     this.isFetchingDivision = true;

    //     try {
    //       await MDivision.api().findAll({
    //         mHeadquarterId: headQuarter.id,
    //       });

    //       if (this.editData) {
    //         const { mDivisionId } = this.editData;

    //         const mDivision = MDivision.find(mDivisionId);

    //         if (mDivision) {
    //           this.division = {
    //             id: mDivision.id,
    //             label: mDivision.m_division_name,
    //           };
    //         }
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       this.$toast.error(
    //         "ไม่สามารถดึงข้อมูลจังหวัดได้ กรุณาลองใหม่อีกครั้ง"
    //       );
    //     } finally {
    //       this.isFetchingDivision = false;
    //     }
    //   }

    //   this.$set(this.form, "mHeadquarterId", id);
    // },

    // async onDivisionChanged(division) {
    //   let id = null;

    //   this.station = null;

    //   if (division && division.id) {
    //     id = division.id;

    //     this.isFetchingStation = true;

    //     try {
    //       await MStation.api().findAll({
    //         mDivisionId: division.id,
    //       });

    //       if (this.editData) {
    //         const { mStationId } = this.editData;

    //         const mStation = MStation.find(mStationId);

    //         if (mStation) {
    //           this.station = {
    //             id: mStation.id,
    //             label: mStation.m_station_name,
    //           };
    //         }
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       this.$toast.error("ไม่สามารถดึงข้อมูลสถานีได้ กรุณาลองใหม่อีกครั้ง");
    //     } finally {
    //       this.isFetchingStation = false;
    //     }
    //   }

    //   this.$set(this.form, "mDivisionId", id);
    // },

    // onStationChanged(station = null) {
    //   let id = null;

    //   if (station && station.id) {
    //     id = station.id;
    //   }

    //   this.$set(this.form, "mStationId", id);
    // },

    onPrefixChanged(prefix = null) {
      let id = null;

      if (prefix && prefix.id) {
        id = prefix.id;
      }

      this.$set(this.form, "mPrefixId", id);
    },

    onCardtypeChanged(cardtype = null) {
      let id = null;

      if (cardtype && cardtype.id) {
        id = cardtype.id;
      }

      this.$set(this.form, "mCardtypeId", id);
    },

    // onBirthDateChanged(date) {
    //   const dObj = new Date(date.getTime());

    //   this.$set(this.form, "birth_date", formatBuddhistBirthDate(dObj));

    //   dObj.setFullYear(dObj.getFullYear() + 543);
    //   dObj.setHours(0);
    //   dObj.setMinutes(0);
    //   dObj.setSeconds(0);

    //   // const timeOffset = dObj.getTimezoneOffset();

    //   // if (timeOffset < 0) {
    //   //   dObj.setMinutes(dObj.getMinutes() - timeOffset);
    //   // } else {
    //   //   dObj.setMinutes(dObj.getMinutes() + timeOffset);
    //   // }

    //   this.$set(this.form, "bd_date", dObj.toISOString());
    // },

    // async onSchoolChanged(school = null) {
    //   let id = null;

    //   this.schoolClass = null;

    //   if (school && school.id) {
    //     id = school.id;

    //     this.isFetchingSchool = true;

    //     try {
    //       await MClass.api().findAll();

    //       if (this.editData) {
    //         const { mClassId } = this.editData;

    //         const mClass = MClass.find(mClassId);

    //         if (mClass) {
    //           this.schoolClass = {
    //             id: mClass.id,
    //             label: mClass.m_class_name,
    //           };
    //         }
    //       }
    //     } catch (error) {
    //       console.error(error);
    //       this.$toast.error(
    //         "ไม่สามารถดึงข้อมูลโรงเรียนได้ กรุณาลองใหม่อีกครั้ง"
    //       );
    //     } finally {
    //       this.isFetchingSchool = false;
    //     }
    //   }

    //   this.$set(this.form, "mSchoolId", id);
    // },

    // onSchoolClassChanged(schoolClass = null) {
    //   let id = null,
    //     room = "";

    //   if (schoolClass && schoolClass.id) {
    //     id = schoolClass.id;
    //     room = schoolClass.label;
    //   }

    //   this.$set(this.form, "mClassId", id);
    //   this.$set(this.form, "room", room);
    // },

    // onFiscalYearChanged(fiscalYear = "") {
    //   this.$set(this.form, "year", fiscalYear);
    // },

    syncData(v) {
      this.$emit("change", v);
    },
  },

  mounted() {
    this.init();
  },
};
</script>
